import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginatorIntlService } from '@core/services/mat-paginator-intl.service';
import { IdTitleSelector, IdValueSelector } from '@core/models/ui.models';
import { MatCheckboxChange } from '@angular/material/checkbox';

export type NektaTableColumn<T extends string = string> = {
    id: T;
    header: string;
    filterOptions?: IdTitleSelector[];
    sort?: boolean;
    thRef?: TemplateRef<any>;
    tdRef?: TemplateRef<any>;
    withAction?: boolean;
};

export type NektaTablePagination = {
    length?: number;
    pageIndex?: number;
    pageSize?: number;
    pageSizeOptions?: number[];
};

/**
 * @description универсальная таблица с фиксированным стилем
 * @param actionsRef шаблон для последнего столбца, виден всегда при скролле
 * @param expandedRef шаблон для компонента, который отображается по клику по строке
 * @param footerRef шаблон для компонента, который отображается внутри футера (строка футера на всю длину таблицы)
 * @param data список типизированных элементов
 * @param displayedColumns список отображаемых столбцов
 * @param isSpinnerVisible индикатор состояния загрузки
 * @param filter объект для добавления элемента фильтр для определенного столбца
 * @param customErrorMessage кастомное сообщение об ошибке если нет данных (data = []), должно быть сразу с переводом
 * @param onlyLocalData индикатор использования только локальных данных компонента,
 * если true, то сортировка и пагинация выполняются с помощью Material Table
 * @param pagination параметры пагинации (опции кол-ва элементов на странице, текущая страница, общее кол-во)
 * @param sort параметр сортировки - выбранный столбец и направление
 * @param tableColumns список с параметрами по каждому столбцу
 * @param trackByKey ключ по которому определяется уникальность элемента
 * @param withoutOptions выключатель блока опций над таблицей
 * @param withCheckboxes добавляет первым столбцом чекбоксы для управления элементами таблицы
 * @param withSettings добавляет фиксированную кнопку настроек, которыми можно выбрать столбцы для отображения
 * сам метод описывается в родительском компоненте

 * @param clearFilters эмиттер очистки фильтров, если данных нету
 * @param filterChange эмиттер изменения фильтра элемента
 * @param paginationChange эмиттер пагинации
 * @param selectedChange эмиттер выбора элементов чекбоксами
 * @param settingsChange эмиттер изменения настроек отображения стобцов
 * @param sortChange эмиттер сортировки
 * @example
 * пример для локальных данных /src/app/cloud/users/users-list/users-list.component.ts
 * пример для серверных данных /src/app/cloud/devices/pages/lists/gateways-list/gateways-list.component.ts
 */

@Component({
    selector: 'nekta-table',
    templateUrl: './nekta-table.component.html',
    styleUrls: ['./nekta-table.component.less'],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlService
        }
    ]
})
export class NektaTableComponent<T extends string = string> implements OnInit, OnChanges, AfterViewInit {
    @Input() actionsRef: TemplateRef<any> | null = null;
    @Input() expandedRef: TemplateRef<any> | null = null;
    @Input() footerRef: TemplateRef<any> | null = null;
    @Input() data: any[] = [];
    @Input() displayedColumns: string[] = [];
    @Input() isSpinnerVisible = false;
    @Input() filter = null;
    @Input() customErrorMessage: string | null = null;
    @Input() onlyLocalData = false;
    @Input() pagination: NektaTablePagination | null = null;
    @Input() sort: Sort | null = null;
    @Input() tableColumns: NektaTableColumn<T>[] = [];
    @Input() trackByKey: string | null = null;
    @Input() withoutOptions = false;
    @Input() withCheckboxes = false;
    @Input() withSettings = false;

    @Output() clearFilters = new EventEmitter<void>();
    @Output() filterChange = new EventEmitter<IdValueSelector | null>();
    @Output() paginationChange = new EventEmitter<PageEvent | null>();
    @Output() rowClick = new EventEmitter<any>();
    @Output() selectedChange = new EventEmitter<any[]>();
    @Output() setExpandedElement = new EventEmitter<any>();
    @Output() settingsChange = new EventEmitter<number>();
    @Output() sortChange = new EventEmitter<Sort | null>();

    @ViewChild('table', { read: MatSort }) matSort: MatSort;
    @ViewChild('paginator', { read: MatPaginator }) matPaginator: MatPaginator;

    public dataSource = new MatTableDataSource<any>();
    public expandedElement = null;
    private selectedItems = [];

    constructor() {}

    ngOnInit(): void {
        this.dataSource.data = this.data;
        this.dataSource.sort = this.matSort;
        this.dataSource.paginator = this.matPaginator;
        if (this.actionsRef) {
            this.displayedColumns.push('actions');
        }
        if (this.withCheckboxes) {
            this.displayedColumns.unshift('checkbox');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes?.data?.previousValue !== changes?.data?.currentValue ||
            changes?.sort?.previousValue !== changes?.sort?.currentValue ||
            changes?.filter?.previousValue !== changes?.filter?.currentValue
        ) {
            this.dataSource.data = this.data ?? [];
            if (this.onlyLocalData) {
                this.dataSource.sort = this.matSort;
                this.dataSource.paginator = this.matPaginator;
            }
            this.selectedItems = [];
        }

        if (
            changes?.displayedColumns?.previousValue !== changes?.displayedColumns?.currentValue &&
            !changes?.displayedColumns?.isFirstChange()
        ) {
            if (this.actionsRef && !this.displayedColumns.includes('actions')) {
                this.displayedColumns.push('actions');
            }
            if (this.withCheckboxes && !this.displayedColumns.includes('checkbox')) {
                this.displayedColumns.unshift('checkbox');
            }
        }
    }

    ngAfterViewInit() {
        if (this.onlyLocalData) {
            this.dataSource.sort = this.matSort;
            this.dataSource.paginator = this.matPaginator;
        }
    }

    changeSelectItem($event: MatCheckboxChange, item) {
        const key = this.trackByKey ?? 'id';
        if ($event.checked) {
            this.selectedItems.push(item[key]);
        } else {
            this.selectedItems = this.selectedItems.filter((w) => w !== item[key]);
        }
        this.selectedChange.emit(this.selectedItems);
    }

    isAllChecked() {
        return this.data.length ? this.data.length === this.selectedItems.length : false;
    }

    isItemChecked(item: any) {
        const key = this.trackByKey ?? 'id';
        return this.selectedItems.includes(item[key]);
    }

    selectAllItems(event: MatCheckboxChange) {
        const key = this.trackByKey ?? 'id';
        this.selectedItems = event.checked ? this.data.map((w) => w[key]) : [];
        this.selectedChange.emit(this.selectedItems);
    }

    onExpandElement(item) {
        this.expandedElement = this.expandedElement === item ? null : item;
        this.setExpandedElement.emit(this.expandedElement);
    }

    onFilterChange(column: T, e: any | null) {
        this.filterChange?.emit({ id: column, value: e });
    }

    onFilterClear() {
        this.clearFilters.emit();
    }

    onPaginationChange(e: PageEvent) {
        this.paginationChange?.emit(e);
    }

    onRowClick(row) {
        this.rowClick.emit(row);
    }

    onSettingsChange(index: number) {
        this.settingsChange?.emit(index);
    }

    onSort(w: Sort | null) {
        this.sortChange?.emit(w);
    }

    trackByFn(index, item) {
        return item?.[this.trackByKey ?? 'id'];
    }
}
