import { Injectable } from '@angular/core';
import { IdTitleSelector } from '@core/models/ui.models';
import { APP_DEFAULT_TIME_ZONE } from '@core/constants/app.cloud.constants';
import { DateTime } from 'luxon';

// TODO: clean from unused methods

// для добавления новых форматов отображения времени - добавить в этот массив
const NEKTA_DATE_FORMATS = [
    'dd.MM.yyyy, HH:mm:ss',
    'dd.MM.yyyy, HH:mm',
    'dd_MM_yyyy_HH_mm',
    'dd.MM.yyyy',
    'ddMMyyyy',
    'HH:mm:ss',
    'HH:mm'
] as const;
export type NektaDateFormat = (typeof NEKTA_DATE_FORMATS)[number];

const ONE_HOUR_IN_MS = 60 * 60 * 1000;
const ONE_HOUR_IN_SEC = 3600;

export type TimeZoneType = 'utc' | 'user' | 'company';

/**
 * @description сервис для преобразования дат в разные форматы. рекомендуется использовать в основе библиотеку luxon
 */

@Injectable({
    providedIn: 'root'
})
export class DateService {
    constructor() {}

    private _timeZone = APP_DEFAULT_TIME_ZONE; // default Nekta main office timezone

    /**
     * часовой пояс пользователя
     */
    get timeZone() {
        return this._timeZone;
    }

    set timeZone(value: number) {
        if (value > 12 || value < -12 || value === undefined || value === null) {
            return;
        }
        this._timeZone = value;
    }

    private _companyTimeZone = APP_DEFAULT_TIME_ZONE; // default Nekta main office timezone

    get companyTimeZone() {
        return this._companyTimeZone;
    }

    set companyTimeZone(value: number) {
        if (value > 12 || value < -12 || value === undefined || value === null) {
            return;
        }
        this._companyTimeZone = value;
    }

    getFormattedDate(timestamp: any, withComma = true) {
        if (!timestamp) {
            return '-';
        }
        const jsTime = this.getTimeStampInMilliSeconds(timestamp);
        const date = new Date(jsTime);
        // TODO: find type for this
        const options: any = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };
        if (withComma) {
            return new Intl.DateTimeFormat('ru-RU', options).format(date);
        } else {
            return new Intl.DateTimeFormat('ru-RU', options).format(date).replace(',', '');
        }
    }

    getUTCDateTime(timestamp: any, withComma = true) {
        if (!timestamp) {
            return '-';
        }
        const jsTime = this.getTimeStampInMilliSeconds(timestamp);
        const date = new Date(jsTime);
        if (withComma) {
            return date.toLocaleString('ru-Ru', { timeZone: 'UTC' });
        } else {
            return date.toLocaleString('ru-Ru', { timeZone: 'UTC' }).replace(',', '');
        }
    }

    getLocalDateStamp(timestampUTC) {
        if (!timestampUTC) {
            return '-';
        }
        const jsTime = this.isTimeStampInSeconds(timestampUTC) ? timestampUTC * 1000 : timestampUTC;
        return jsTime + this.timeZone + ONE_HOUR_IN_MS * this.timeZone;
    }

    getOnlyTime(timestamp) {
        const jsTime = this.getTimeStampInMilliSeconds(timestamp);
        const date = new Date(jsTime);
        // TODO: find type for this
        const options: any = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return new Intl.DateTimeFormat('ru-RU', options).format(date);
    }

    /**
     * возвращает массив часовых поясов в виде объектов {id: 1, title: GMT-1} для использования в селекторах
     */
    getTimeZonesForSelector(): IdTitleSelector<number, string>[] {
        const result: IdTitleSelector<number, string>[] = [];
        for (let i = -12; i < 13; i++) {
            result.push({ id: i, title: `GMT ${i >= 0 ? '+' : ''}${i}` });
        }
        return result;
    }

    /**
     * возвращает время в формате DateTime(luxon) с поправкой на часовой пояс или null для невалидного значения
     * @param timestamp - в секундах
     * @param timeZoneType - часовой пояс компании, пользователя или utc
     */
    getLuxonDateFromTimestamp(timestamp: number | null | undefined, timeZoneType: TimeZoneType = 'user'): DateTime | null {
        if (!timestamp || typeof timestamp !== 'number') {
            return null;
        }
        const offsetHours = this.getOffsetByTimeZoneType(timeZoneType);
        return DateTime.fromSeconds(timestamp).toUTC().plus({ hours: offsetHours });
    }

    /**
     * возвращает таймстамп с поправкой на часовой пояс или  null для невалидного значения
     * @param luxon - Datetime
     * @param timeZoneType - часовой пояс компании, пользователя или utc
     */
    getTimestampFromLuxon(luxon: DateTime | null | undefined, timeZoneType: TimeZoneType = 'user'): number | null {
        if (!DateTime.isDateTime(luxon)) {
            return null;
        }
        const offsetHours = this.getOffsetByTimeZoneType(timeZoneType);
        return Math.floor(luxon.toUTC().minus({ hours: offsetHours }).toSeconds());
    }

    /**
     * возвращает строковое значение даты в определенном формате
     * @param date дата в виде DateTime(luxon) или таймштамп в секундах
     * @param format один из типовых форматов отображения
     * @param timeZoneType - часовой пояс компании, пользователя или utc
     */
    getDateAsFormatString(
        date: DateTime | number | null | undefined,
        format: NektaDateFormat = 'dd.MM.yyyy, HH:mm',
        timeZoneType: TimeZoneType = 'user'
    ): string {
        if (!date) {
            return '-';
        }
        const luxonDate = DateTime.isDateTime(date) ? date : this.getLuxonDateFromTimestamp(date, timeZoneType);
        return luxonDate.toFormat(format);
    }

    private isTimeStampInSeconds(timestamp: any): boolean {
        return Math.round(timestamp)?.toString()?.length < 11;
    }

    private getTimeStampInMilliSeconds(timestamp) {
        return this.isTimeStampInSeconds(timestamp) ? timestamp * 1000 : timestamp;
    }

    private getOffsetByTimeZoneType(timeZoneType: TimeZoneType): number {
        if (timeZoneType === 'user') {
            return this.timeZone;
        }
        if (timeZoneType === 'company') {
            return this.companyTimeZone;
        }
        return 0;
    }
}
