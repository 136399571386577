// В этом файле собраны константы, влияющие на приложение целиком, например дефолтный путь к фавиконке

export const APP_FAVICON_SRC = '/assets/favicon_nekta.png';
export const APP_TITLE = 'NEKTA CLOUD';
export const APP_HEADER_LOGO_SRC = '/assets/img/logo.png';
export const APP_AUTH_LOGO_SRC = '/assets/img/logo2.png';
export const APP_SNACKBAR_DURATION = 5000; // 5 sec
export const APP_DEMO_USERS = ['demo@nekta.tech', 'demo@nekta.cloud'];
export const APP_LANGUAGES = ['en', 'ru'];
export const APP_DEFAULT_TIME_ZONE = 4; // default Nekta main office timezone
export const ResourceTypeList = [
    { id: 1, meteringDevicesType: 1, accountingPointsTypes: [1, 2, 8, 9], title: 'Вода', title_en: 'Water' },
    { id: 2, meteringDevicesType: 2, accountingPointsTypes: [5], title: 'Газ', title_en: 'Gas' },
    { id: 3, meteringDevicesType: 3, accountingPointsTypes: [4, 7], title: 'Тепло', title_en: 'Heat' },
    { id: 4, meteringDevicesType: 4, accountingPointsTypes: [3], title: 'Электричество', title_en: 'Electricity' },
    { id: 5, meteringDevicesType: 5, accountingPointsTypes: [6], title: 'Вещество', title_en: 'Substance' },
    { id: 6, meteringDevicesType: 6, accountingPointsTypes: [], title: 'Датчик', title_en: 'Sensor' },
    { id: 7, meteringDevicesType: 7, accountingPointsTypes: [], title: 'Прочее', title_en: 'Other' }
];
