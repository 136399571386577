import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DevicesService} from '../../../../../../services/devices.service';
import {ErrorsService} from '@core/services/errors.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import {takeUntil} from 'rxjs/operators';
import {TranslateLanguageService} from '../../../../../../../../core/services/translate-language.service';
import {Subject} from 'rxjs';
import {MY_FORMATS} from '@core/constants/consts';

@Component({
    selector: 'app-get-archives-modal',
    templateUrl: './get-archives-modal.component.html',
    styleUrls: ['./get-archives-modal.component.less'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ],
})
export class GetArchivesModalComponent implements OnInit, OnDestroy {
    public disableEdit = false;
    archiveType = [
        { value: 0, viewValue: 'Часовой' },
        { value: 1, viewValue: 'Дневной' },
        { value: 2, viewValue: 'Месячный' }
    ];
    archiveSendObject = {
        id: 0,
        management: {
            type: null,
            archive: null,
            archiveTime: null
        }
    };
    private destroyed$: Subject<void> = new Subject<void>();
    constructor(
        private _devicesService: DevicesService,
        private errors: ErrorsService,
        public dialogRef: MatDialogRef<GetArchivesModalComponent>,
        private dateAdapter: DateAdapter<Date>,
        private translateLanguageService: TranslateLanguageService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.translateLanguageService.currentLanguage$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((lang) => {
                this.dateAdapter.setLocale(lang);
            });
        this.archiveSendObject.id = _.get(this.data, 'device_id');
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    getArchive() {
        this.disableEdit = true;
        const body = _.cloneDeep(this.archiveSendObject);
        if (
            this.archiveSendObject.management.hasOwnProperty('archive') ||
            this.archiveSendObject.management.hasOwnProperty('archiveTime')
        ) {
            if (this.archiveSendObject.management['type'] === 0) {
                const time = body.management['archiveTime'];
                delete body.management['archiveTime'];
                body.management['archive'] =
                    moment(this.archiveSendObject.management['archive']).format('YYYY-MM-DD') + ' ' + (time || '00') + ':00';
            } else if (
                _.eq(this.archiveSendObject.management['type'], 2) ||
                _.eq(this.archiveSendObject.management['type'], 1)
            ) {
                body.management['archive'] = moment(this.archiveSendObject.management['archive']).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
                delete body.management['archiveTime'];
            } else {
                body.management['archive'] =
                    moment(this.archiveSendObject.management['archive']).format('YYYY-MM-DD') +
                    ' ' +
                    (body.management['archiveTime'] || '00') +
                    ':00';
                delete body.management['archiveTime'];
            }
        }
        this._devicesService.setControl(body, `Ручной сбор архивов`).then(
            (response: any) => {
                this.disableEdit = false;
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                this.disableEdit = false;
                throw error;
            }
        );
    }

    changeType() {
        const type = this.archiveSendObject.management['type'];
        this.archiveSendObject.management = {
            type: null,
            archive: null,
            archiveTime: null
        };
        this.archiveSendObject.management['type'] = type;
    }
}
