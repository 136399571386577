import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EditGatewayComponent } from 'src/app/cloud/devices/components/edit/edit-gateway/edit-gateway.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { MomentModule } from 'ngx-moment';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { DeviceAddComponent } from 'src/app/cloud/devices/components/add/device/device.component';
import { HttpClientModule } from '@angular/common/http';
import { MeteringDevicesDataComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data.component';
import { SelectDateComponent } from 'src/app/cloud/devices/pages/data/metering-devices/select-date/select-date.component';
import { MeteringDevicesPowerProfileComponent } from 'src/app/cloud/devices/pages/data/metering-devices/power_profile/power_profile.component';
import { MeteringDevicesArchivesSystem1Component } from 'src/app/cloud/devices/pages/data/metering-devices/archives_system_1/archives_system_1.component';
import { MeteringDevicesArchivesSystem2Component } from 'src/app/cloud/devices/pages/data/metering-devices/archives_system_2/archives_system_2.component';
import { CloudComponent } from 'src/app/cloud/cloud.component';
import { RouterModule } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DeviceComponent } from 'src/app/cloud/devices/components/control/device/device.component';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MeteringDevicesDataShowComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data-show/data-show.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MeteringDevicesJournalComponent } from 'src/app/cloud/devices/pages/data/metering-devices/journal/journal.component';
import { GetCurrentValuesComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data-show/components/get-current-values/get-current-values.component';
import { GetPowerProfileComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data-show/components/get-power-profile/get-power-profile.component';
import { GetPowerProfileModalComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data-show/components/get-power-profile/get-power-profile-modal';
import { TreeComponent } from 'src/app/cloud/objects/components/tree/tree.component';
import { GetArchivesModalComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data-show/components/get-archives-modal/get-archives-modal.component';
import { GetEventLogsModalComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data-show/components/get-event-logs-modal/get-event-logs-modal.component';
import { AccountingPointTransformersComponent } from 'src/app/cloud/objects/components/accounting-points/accountin-point-transformers/accounting-point-transformers.component';
import { AccountingPointLogsComponent } from 'src/app/cloud/objects/components/accounting-points/accounting-point-logs/accounting-point-logs.component';
import { AccountingPointTypeFilterComponent } from 'src/app/shared/components/accounting-point-type-filter/accounting-point-type-filter.component';
import { DatetimeCalendarComponent } from 'src/app/shared/components/datetime-calendar/datetime-calendar.component';
import { EditAccountingPointComponent } from 'src/app/cloud/objects/components/popups/edit-accounting-point/edit-accounting-point.component';
import { EditComponent } from 'src/app/cloud/objects/components/popups/edit/edit.component';
import { ObjectMapSelectorComponent } from 'src/app/cloud/objects/components/popups/popup-object/object-map-selector/object-map-selector.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AccountingPointCardModalComponent } from 'src/app/cloud/devices/components/accounting-point-card-modal/accounting-point-card-modal.component';
import { AccountingPointCardComponent } from 'src/app/cloud/devices/pages/accounting-point-card/accounting-point-card.component';
import { DeviceEditFormComponent } from 'src/app/cloud/devices/components/device-edit-form/device-edit-form.component';
import { DeviceAttributesComponent } from 'src/app/cloud/devices/components/device-attributes-container/device-attributes/device-attributes.component';
import { AlmatySuTabsComponent } from 'src/app/cloud/devices/components/almaty_su/almaty-su-tabs/almaty-su-tabs.component';
import { EventsListComponent } from 'src/app/cloud/events/events-list/events-list.component';
import { TriggeredEventsComponent } from 'src/app/cloud/events/triggered-events/triggered-events.component';
import { WarmPanelComponent } from 'src/app/cloud/dashboard/widgets/warm-panel/warm-panel.component';
import { ElectricalPanelComponent } from 'src/app/cloud/dashboard/widgets/electrical-panel/electrical-panel.component';
import { GasPanelComponent } from 'src/app/cloud/dashboard/widgets/second-water-panel/gas-panel.component';
import { FormAddDeviceComponent } from 'src/app/cloud/devices/components/add/device/form/form-add-device/form-add-device.component';
import { FormTemplateComponent } from 'src/app/cloud/devices/components/add/device/form/form-template/form-template.component';
import { StatusComponent } from 'src/app/shared/components/status/status.component';
import { WaterPanelComponent } from 'src/app/cloud/dashboard/widgets/water-panel/water-panel.component';
import { SearchComponent } from 'src/app/cloud/devices/consts/search/search.component';
import { TariffSettingComponent } from 'src/app/cloud/devices/components/control/tariff-setting/tariff-setting.component';
import { DeviceAttributesContainerComponent } from 'src/app/cloud/devices/components/device-attributes-container/device-attributes-container.component';
import { CreateDeviceAlmatyComponent } from 'src/app/cloud/devices/components/almaty_su/components/create-device-almaty/create-device-almaty.component';
import { AttachDeviceAlmatyComponent } from 'src/app/cloud/devices/components/almaty_su/components/attach-device-almaty/attach-device-almaty.component';
import { EditDeviceAlmatyComponent } from 'src/app/cloud/devices/components/almaty_su/components/edit-device-almaty/edit-device-almaty.component';
import { TemperaturePanelComponent } from 'src/app/cloud/dashboard/widgets/temperature-panel/temperature-panel.component';
import { ConcatDeviceAddressPipe, GetTimeFromMinPipe, GetTitlePipe, ToFixedDecimalPipe, TranslateMsgTypePipe } from '@core/pipes';
import { InputMinMaxValidateDirective } from '@core/directives/input-min-max-validate.directive';
import { CountryPickerComponent } from 'src/app/shared/components/country-picker/country-picker.component';
import { AccountingPointStandardComponent } from 'src/app/cloud/devices/pages/accounting-point-card/components/accounting-point-standard/accounting-point-standard.component';
import { HumidityPanelComponent } from 'src/app/cloud/dashboard/widgets/humidity-panel/humidity-panel.component';
import { ChargeBatteryComponent } from 'src/app/shared/components/charge-battery/charge-battery.component';
import { InfrastructureReportTreeComponent } from 'src/app/cloud/reports/infrastructure-reports/components/infrastructure-report-tree/infrastructure-report-tree.component';
import { DisplayIndicationsComponent } from 'src/app/cloud/devices/pages/data/metering-devices/data-show/components/display-indications/display-indications.component';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { MomentJsPipe } from '@core/pipes/momentjs.pipe';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '@core/material.module';
import { SpecialDaysSelectorComponent } from 'src/app/cloud/devices/components/control/special-days-selector/special-days-selector.component';
import { GatewaysDataJournalComponent } from 'src/app/cloud/devices/pages/data/gateways/gateways-data-journal/gateways-data-journal.component';
import { DataGatewaysComponent } from 'src/app/cloud/devices/pages/data/gateways/data.component';
import { GatewaysDataComponent } from 'src/app/cloud/devices/pages/data/gateways/data/data.component';
import { ClockComponent } from '@shared/components/clock/clock.component';
import { ConfirmActionComponent } from '@shared/components/confirm-action/confirm-action.component';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { AlertWithInputComponent } from '@shared/components/alert-with-input/alert-with-input.component';
import { NektaSearchComponent } from '@shared/components/nekta-search/nekta-search.component';
import { NektaTableComponent } from '@shared/components/nekta-table/nekta-table.component';
import { NektaTableFilterComponent } from '@shared/components/nekta-table-filter/nekta-table-filter.component';
import { NektaDualListComponent } from '@shared/components/nekta-dual-list/nekta-dual-list.component';
import { OtherPanelComponent } from '@cloud/dashboard/widgets/other-panel/other-panel.component';
import { PressurePanelComponent } from '@cloud/dashboard/widgets/pressure-panel/pressure-panel.component';
import { NektaDeviceGroupSelectorComponent } from '@shared/components/nekta-device-group-selector/nekta-device-group-selector.component';
import { NektaStatusComponent } from '@shared/components/nekta-status/nekta-status.component';
import { NektaFileLoaderComponent } from '@shared/components/nekta-file-loader/nekta-file-loader.component';
import { HelpInfoComponent } from '@shared/components/help-info/help-info.component';
import { FileImportDetailsComponent } from '@shared/components/file-import-details/file-import-details.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AlertWithSelectComponent } from '@shared/components/alert-with-select/alert-with-select.component';
import { NgxMaskModule } from 'ngx-mask';
import { SmallIdTitleTableComponent } from '@shared/components/small-id-title-table/small-id-title-table.component';
import { NektaResourceTypeSelectorComponent } from '@shared/components/nekta-resource-type-selector/nekta-resource-type-selector.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BaseTreeComponent } from '@shared/components/base-tree/base-tree.component';
import { TwoFactorAuthenticationComponent } from '@shared/components/two-factor-authentication/two-factor-authentication';
import { DevicePollModalComponent } from '@shared/components/device-poll-modal/device-poll-modal.component';
import { TreeWithStatsComponent } from '@shared/components/nekta-tree/tree-with-stats/tree-with-stats.component';
import { NektaTreeComponent } from '@shared/components/nekta-tree/nekta-tree.component';
import { VectorDiagramComponent } from '@shared/components/vector-diagram/vector-diagram.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [
        MomentJsPipe,
        EditGatewayComponent,
        DeviceAddComponent,
        SelectDateComponent,
        MeteringDevicesDataComponent,
        MeteringDevicesPowerProfileComponent,
        MeteringDevicesArchivesSystem1Component,
        MeteringDevicesArchivesSystem2Component,
        DeviceComponent,
        MeteringDevicesDataShowComponent,
        MeteringDevicesJournalComponent,
        GetCurrentValuesComponent,
        GetPowerProfileComponent,
        GetPowerProfileModalComponent,
        GetEventLogsModalComponent,
        GetArchivesModalComponent,
        DisplayIndicationsComponent,
        TreeComponent,
        AccountingPointTransformersComponent,
        AccountingPointLogsComponent,
        AccountingPointTypeFilterComponent,
        EditAccountingPointComponent,
        EditComponent,
        ObjectMapSelectorComponent,
        DeviceEditFormComponent,
        AccountingPointCardComponent,
        AccountingPointCardModalComponent,
        DeviceAttributesComponent,
        DeviceAttributesContainerComponent,
        AlmatySuTabsComponent,
        AccountingPointStandardComponent,
        EventsListComponent,
        TriggeredEventsComponent,
        DatetimeCalendarComponent,
        WarmPanelComponent,
        WaterPanelComponent,
        OtherPanelComponent,
        ElectricalPanelComponent,
        PressurePanelComponent,
        GasPanelComponent,
        HumidityPanelComponent,
        FormAddDeviceComponent,
        FormTemplateComponent,
        StatusComponent,
        SearchComponent,
        TariffSettingComponent,
        CreateDeviceAlmatyComponent,
        AttachDeviceAlmatyComponent,
        EditDeviceAlmatyComponent,
        TemperaturePanelComponent,
        TranslateMsgTypePipe,
        GetTimeFromMinPipe,
        InputMinMaxValidateDirective,
        CountryPickerComponent,
        ChargeBatteryComponent,
        ToFixedDecimalPipe,
        ConcatDeviceAddressPipe,
        GetTitlePipe,
        InfrastructureReportTreeComponent,
        SnackbarComponent,
        SpecialDaysSelectorComponent,
        GatewaysDataJournalComponent,
        DataGatewaysComponent,
        GatewaysDataComponent,
        ClockComponent,
        ConfirmActionComponent,
        AlertComponent,
        AlertWithInputComponent,
        NektaSearchComponent,
        NektaTableComponent,
        NektaTableFilterComponent,
        NektaDualListComponent,
        NektaDeviceGroupSelectorComponent,
        NektaStatusComponent,
        NektaFileLoaderComponent,
        HelpInfoComponent,
        FileImportDetailsComponent,
        AlertWithSelectComponent,
        SmallIdTitleTableComponent,
        NektaResourceTypeSelectorComponent,
        BaseTreeComponent,
        TreeWithStatsComponent,
        NektaTreeComponent,
        TwoFactorAuthenticationComponent,
        DevicePollModalComponent,
        VectorDiagramComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        VirtualScrollerModule,
        AngularDualListBoxModule,
        MomentModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        NgxMatSelectSearchModule,
        NgxJsonViewerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaterialTimepickerModule,
        HttpClientModule,
        LeafletModule,
        RouterModule,
        MaterialModule,
        QRCodeModule,
        NgxMaskModule,
        AngularSvgIconModule
    ],
    exports: [
        MaterialModule,
        TranslateModule,
        LeafletModule,
        MomentJsPipe,
        VirtualScrollerModule,
        SelectDateComponent,
        PerfectScrollbarModule,
        AngularDualListBoxModule,
        MomentModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        NgxJsonViewerModule,
        HumidityPanelComponent,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxMaterialTimepickerModule,
        TreeComponent,
        DeviceComponent,
        AccountingPointTransformersComponent,
        AccountingPointLogsComponent,
        AccountingPointTypeFilterComponent,
        EditAccountingPointComponent,
        EditComponent,
        ObjectMapSelectorComponent,
        AccountingPointCardComponent,
        DeviceEditFormComponent,
        AccountingPointCardModalComponent,
        DeviceAttributesComponent,
        DeviceAttributesContainerComponent,
        AlmatySuTabsComponent,
        AccountingPointStandardComponent,
        DeviceAddComponent,
        EventsListComponent,
        TriggeredEventsComponent,
        DatetimeCalendarComponent,
        WarmPanelComponent,
        WaterPanelComponent,
        OtherPanelComponent,
        ElectricalPanelComponent,
        PressurePanelComponent,
        GasPanelComponent,
        FormTemplateComponent,
        StatusComponent,
        SearchComponent,
        TariffSettingComponent,
        TemperaturePanelComponent,
        TranslateMsgTypePipe,
        GetTimeFromMinPipe,
        InputMinMaxValidateDirective,
        CountryPickerComponent,
        ChargeBatteryComponent,
        ToFixedDecimalPipe,
        ConcatDeviceAddressPipe,
        GetTitlePipe,
        InfrastructureReportTreeComponent,
        SnackbarComponent,
        FormAddDeviceComponent,
        SpecialDaysSelectorComponent,
        MeteringDevicesJournalComponent,
        GatewaysDataJournalComponent,
        MeteringDevicesDataShowComponent,
        MeteringDevicesDataComponent,
        DataGatewaysComponent,
        GatewaysDataComponent,
        ClockComponent,
        ConfirmActionComponent,
        AlertComponent,
        AlertWithInputComponent,
        NektaSearchComponent,
        NektaTableComponent,
        NektaTableFilterComponent,
        NektaDualListComponent,
        NektaDeviceGroupSelectorComponent,
        NektaStatusComponent,
        NektaFileLoaderComponent,
        HelpInfoComponent,
        FileImportDetailsComponent,
        NektaStatusComponent,
        QRCodeModule,
        AlertWithSelectComponent,
        NgxMaskModule,
        SmallIdTitleTableComponent,
        NektaResourceTypeSelectorComponent,
        BaseTreeComponent,
        TwoFactorAuthenticationComponent,
        DevicePollModalComponent,
        TreeWithStatsComponent,
        NektaTreeComponent,
        VectorDiagramComponent
    ],
    providers: [
        CloudComponent,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ]
})
export class SharedModule {}
