import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DevicesService} from '../../../services/devices.service';
import {ButtonsService, CheckAccess, CloudService} from '../../../../cloud.service';
import 'moment-timezone';
import {ReplaySubject, Subject} from 'rxjs';
import {CloudComponent} from '../../../../cloud.component';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import * as fromModels from '../../../../../store/actions/models.action';
import * as _ from 'lodash';
import {ErrorsService} from '@core/services/errors.service';
import {DeviceComponent} from '../../../components/control/device/device.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {ObjectsService} from '../../../../objects/objects.service';
import {GetArchivesModalComponent} from './data-show/components/get-archives-modal/get-archives-modal.component';
import {GetEventLogsModalComponent} from './data-show/components/get-event-logs-modal/get-event-logs-modal.component';
import {DisplayIndicationsComponent} from './data-show/components/display-indications/display-indications.component';
import {SelectDateComponent} from './select-date/select-date.component';

@Component({
    selector: 'metering-devices-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.less']
})
export class MeteringDevicesDataComponent implements OnInit, OnDestroy {
    @ViewChild(SelectDateComponent) selectDateComponent: SelectDateComponent;
    @Output() refreshData = new Subject();
    public currentDevice;
    public currentAccountingPointTabs: any = null;
    public messageGroups = [];
    public msgTypes = [];
    public dateForm: any = null;
    public msgGroup = 1;
    public msgType = null;
    public profileType = 60;
    public currentTab = 'tariffs';
    public changeCurrentTabEvent = new EventEmitter();
    public deviceId = null;
    public accountingPointId = null;
    public controls = null;
    public selectedDate = null;
    public currentAccountingPoint = null;
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    public disablePagination = false;
    public typeJournal: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private route: ActivatedRoute,
        private devicesService: DevicesService,
        private buttonsService: ButtonsService,
        private translate: TranslateService,
        public cloud: CloudComponent,
        private errors: ErrorsService,
        public _checkAccess: CheckAccess,
        private store: Store<{}>,
        private objectsService: ObjectsService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<MeteringDevicesDataComponent>
    ) {
        // this.deviceId = <number>parseInt(this.route.snapshot.paramMap.get("id"), 0);
        if (!_.isNull(this.route.snapshot.paramMap.get('id'))) {
            this.deviceId = <number>parseInt(this.route.snapshot.paramMap.get('id'), 0);
        }
        if (!_.isNull(this.route.snapshot.paramMap.get('point_id'))) {
            this.accountingPointId = this.route.snapshot.paramMap.get('point_id');
        }
        if (!_.isNull(this.data) && !_.isNull(_.get(this.data, 'deviceId', null))) {
            this.deviceId = this.data.deviceId;
        }
        if (!_.isNull(this.data) && !_.isNull(_.get(this.data, 'accountingPointId', null))) {
            this.accountingPointId = this.data.accountingPointId;
        }
        if (!_.isNull(this.data) && !_.isNull(_.get(this.data, 'dates', null))) {
            this.selectedDate = this.data.dates;
        }
        if (_.isNull(this.route.snapshot.paramMap.get('id')) && _.isNull(this.data)) {
            this.errors.showSnackbar('msg_84', true);
        }
        this.buttonsService.setButtons([]);
        this.cloud.goToBack = true;
        CloudService.setLanguage(translate);
    }

    ngOnInit() {
        if (this.deviceId !== null) {
            this.devicesService
                .getOneMeteringDevice(this.deviceId)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((device: any) => {
                    this.currentDevice = device;
                    this.store
                        .pipe(
                            select(fromModels.getMeteringDeviceModelById, {
                                id: device.model_id
                            })
                        )
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((model: any) => {
                            this.currentDevice['model'] = model;
                            if (!_.isNull(_.get(model, 'options.control', null))) {
                                this.controls = _.get(model, 'options.control', null);
                            }
                        });
                });
        } else if (this.accountingPointId !== null) {
            this.objectsService.getAccountingPointById(this.accountingPointId).subscribe((result: any) => {
                this.currentAccountingPoint = result;
            });
            this.objectsService.getTabsByAccountingPointId(this.accountingPointId).subscribe((result) => {
                this.currentAccountingPointTabs = result;
            });
        }
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    dateChange(date) {
        this.devicesService.dateForm = date;
        setTimeout(() => {
            this.devicesService.refreshData.next({msgType: this.msgType, tab: this.currentTab});
        });
    }

    msgGroupChange(msgGroup) {
        this.msgGroup = msgGroup;
    }

    typeJournalChange(typeJournal) {
        this.typeJournal = typeJournal;
    }

    msgTypeChange(msgType) {
        this.msgType = msgType;
    }

    powerProfileTypeChange(profileType) {
        this.profileType = profileType;
    }

    changeTab(event) {
        const tab = event.tab._viewContainerRef.element.nativeElement.dataset.id;
        this.currentTab = tab;
        this.changeCurrentTabEvent.emit(tab);
        this.selectDateComponent.changePeriod(false);
        this.disablePagination = true;
        if (this.deviceId !== null) {
            this.msgTypes = _.get(
                this.currentDevice,
                'model.tabs.' + tab + '.msgTypes',
                []
            );
            if (this.msgTypes.length > 1 || this.msgTypes.length === 1) {
                this.msgType = this.msgTypes[0];
            } else if (typeof this.msgTypes === 'number') {
                this.msgType = this.msgTypes;
            } else {
                this.msgType = 1;
            }
            setTimeout(() => {
                this.devicesService.refreshData.next({
                    msgType: this.msgType,
                    tab: tab
                });
                this.disablePagination = false;
            }, 1000);
        } else if (this.accountingPointId !== null) {
            this.msgTypes = _.get(this.currentAccountingPointTabs, tab + '.msgTypes', []);
            if (this.msgTypes.length > 1 || this.msgTypes.length === 1) {
                this.msgType = this.msgTypes[0];
            } else if (typeof this.msgTypes === 'number') {
                this.msgType = this.msgTypes;
            } else {
                this.msgType = 1;
            }
            setTimeout(() => {
                this.devicesService.refreshData.next({
                    msgType: this.msgType,
                    tab: tab
                });
                this.disablePagination = false;
            }, 1000);
        }
    }

    goToControls() {
        this.dialog.open(DeviceComponent, {
            disableClose: false,
            width: '95%',
            height: '95%',
            data: {
                device_id: this.currentDevice.id,
                model_id: this.currentDevice.model_id
            }
        });
    }

    getTariff() {
        const body = {
            id: this.currentDevice.id,
            management: {
                tariff: 1
            }
        };
        this.devicesService.setControl(body, `Ручной сбор тарифов`).then(
            (response: any) => {
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                throw error;
            }
        );
    }

    onReferenceInformation() {
        const body = {
            id: this.currentDevice.id,
            management: {
                read_info: true
            }
        };
        this.devicesService.setControl(body, `Запрос справочной финормации`).then(
            (response: any) => {
                this.errors.showSnackbar(response.data.msg);
            },
            (error) => {
                throw error;
            }
        );
    }

    getArchives() {
        this.dialog.open(GetArchivesModalComponent, {
            data: {
                device_id: this.currentDevice.id,
                model_id: this.currentDevice.model_id
            }
        });

    }

    getEventLogs() {
        this.dialog.open(GetEventLogsModalComponent, {
            width: '450px',
            data: {
                device_id: this.currentDevice.id,
                model_id: this.currentDevice.model_id
            }
        });
    }

    onNavigateAccountingPoint() {
        if (this.deviceId) {
            this.router.navigate([`/cloud/devices/accounting-point-card/${this.deviceId}`],
                { queryParams: { point_id: this.accountingPointId } });
        } else {
            this.router.navigate([`/cloud/devices/accounting-point-card`],
                { queryParams: { point_id: this.accountingPointId } });
        }
        if (this.dialogRef?.close) {
            this.dialogRef.close();
        }

    }

    onDisplayIndications() {
        this.dialog.open(DisplayIndicationsComponent, {
            width: '950px',
            data: {
                device_id: this.currentDevice.id,
                model_id: this.currentDevice.model_id,
                resourceType: this.currentDevice.device_group_id
            }
        });
    }

    onNavigateTaskPoll() {
        this.router.navigate([`/cloud/devices/logs/metering/${this.deviceId}`]);
        if (this.dialogRef?.close) {
            this.dialogRef.close();
        }

    }
}
